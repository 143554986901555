<script>
    import axios from "axios";


    export default {
        page: {title: 'Όροι χρήσης'},

        data() {
            return {
                content: null
            }
        },
        created(){
            this.fetchTerms();
        },

        methods: {

            fetchTerms(){
                let instance = axios.create();

                instance.get('https://cms.athlisis.com/software-terms',{transformRequest: (data, headers) => {
                        delete headers.common['x-dietitian-token'];
                        delete headers.common['x-access-token'];
                    }}).then(res => {
                    this.content = res.data.content;
                });
            }

        }
    }
</script>
<style lang="scss">
    #terms{
        a{
            display:block;
            padding-bottom: 1rem;
            font-weight: bold;
            font-size: 1.1rem;
        }
    }

</style>

<template>
    <div id="terms-layout">
        <div id="terms" class="row">
            <div class="card col-md-12 shadow-sm" style="max-height:70vh;overflow-y:scroll;max-width: 100%;">
                <div v-html="content" class="p-3"></div>
            </div>
        </div>
    </div>


</template>
